import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import styles from './loading-spinner.module.scss';
import PropTypes from 'prop-types';

const LoadingSpinner = ({ isLoading, classes, isResponsive, size }) => {
  if (!isLoading) {
    return null;
  }
  return (
    <div
      className={`${
        isResponsive ? '' : styles.loadingSpinnerContainer
      } ${classes}`}
    >
      <FontAwesomeIcon
        className={`${styles.spin} ${isResponsive ? '' : styles.spinnerIcon}`}
        icon={faSpinner}
        size={size}
      />
    </div>
  );
};

export default LoadingSpinner;

LoadingSpinner.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  classes: PropTypes.string,
  isReponse: PropTypes.bool,
  size: PropTypes.string,
};

LoadingSpinner.defaultProps = {
  classes: '',
  isResponsive: false,
  size: '1x',
};
