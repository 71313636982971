import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import JobsRouter from './../components/jobs/jobs-router';

const Jobs = ({ data, location }) => {
  const [mounted, setMounted] = useState(false);
  const ogImageUrlPath = data.allFile.edges[0].node.publicURL;
  const fullOgImageUrl = `https://www.trustaff.com${ogImageUrlPath}`;

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <Helmet>
        <title>Search travel nursing jobs - Trustaff</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="Search hundreds of travel nursing jobs for all specialties. Instantly apply to high-paying travel nursing jobs at top facilities in all 50 states."
        />
        <meta property="og:title" content="Search travel nursing jobs" />
        <meta
          property="og:description"
          content="Search hundreds of travel nursing jobs for all specialties. Instantly apply to high-paying travel nursing jobs at top facilities in all 50 states."
        />
        <meta property="og:image" content={fullOgImageUrl} />
        <meta property="og:url" content="https://www.trustaff.com/jobs/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <JobsRouter data={data} mounted={mounted} />
    </>
  );
};

export default Jobs;

export const pageQuery = graphql`
  {
    allImageSharp(
      filter: { fluid: { originalName: { regex: "/(state-hero|jobs-list)/" } } }
    ) {
      edges {
        node {
          ... on ImageSharp {
            fluid(maxWidth: 2400) {
              base64
              aspectRatio
              src
              srcSet
              sizes
              originalName
            }
          }
        }
      }
    }
    allFile(filter: { name: { regex: "/jobs-list-og-image/" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`;
