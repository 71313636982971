import React from 'react';
import Helmet from 'react-helmet';

import { getFormattedLocation } from '../../../utils/format-helpers';

const JobDetailsHead = ({
  id,
  specialtyName,
  city,
  state,
  ogImage,
  ogUrl,
  divisionName,
  professionDescription,
}) => {
  const title = `${specialtyName} in ${getFormattedLocation(
    city,
    state
  )} - Job ID #${id}`;

  const description = `Trustaff is currently seeking a skilled ${specialtyName} for a new ${divisionName} ${professionDescription} job in ${getFormattedLocation(
    city,
    state
  )}.`;

  return (
    <Helmet>
      <title>{title} - Trustaff</title>
      <meta name="Description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={ogUrl} />
      <meta
        property="og:image"
        content={`https://www.trustaff.com${ogImage}`}
      />
    </Helmet>
  );
};

export default JobDetailsHead;
