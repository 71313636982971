import React from 'react';
import Img from 'gatsby-image';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

import JobsSearch from '../../jobs-search/jobs-search';
import styles from './jobs-hero-section.module.scss';
const JobsHeroSection = ({ fetchJobs, data }) => {
  const heroImageEdge = data.allImageSharp.edges.find(e =>
    e.node.fluid.originalName.includes('jobs-list-hero')
  );

  return (
    <section className={styles.jobsHeroSection}>
      <Img
        className={`${styles.imgWrapper}`}
        fluid={heroImageEdge.node.fluid}
      />
      <div className={`columns ${styles.heroOverlay}`}>
        <div className={`column is-offset-1 is-6 ${styles.jobsHeroTextArea}`}>
          <h1 className={`title is-3 ${styles.jobsHeroText}`}>
            Search travel nursing jobs
          </h1>
          <p className={`content ${styles.jobsHeroText}`}>
            Positions move fast! Want the inside edge on the latest jobs? Update
            your profile &amp; connect with a recruiter today.
          </p>
          <p className={`is-hidden-mobile ${styles.jobsHeroText}`}>
            Whether you’re a first-timer or a seasoned pro, Trustaff gives you
            instant access to hundreds of high-paying travel nursing positions
            all across the country. We help match healthcare professionals of
            all specialties with travel jobs that will advance your nursing
            career and maximize your pay.
          </p>
          <p
            className={`is-hidden-mobile ${styles.jobsHeroText} ${styles.jobsSmall}`}
          >
            <Link to="/apply/" className={styles.smallLink}>
              Complete your profile
            </Link>{' '}
            <Link to="/nurses/salary-benefits/" className={styles.smallLink}>
              Explore benefits
            </Link>{' '}
            <Link
              to="/travel-nursing/pay-bonuses/"
              className={styles.smallLink}
            >
              Travel nursing pay
            </Link>
          </p>
        </div>

        <div className={`column is-4`}>
          <p
            className={`is-size-5 has-text-weight-semibold ${styles.searchAreaTitle}`}
          >
            I'm looking for...
          </p>
          <JobsSearch onSubmit={fetchJobs} />
        </div>
      </div>
    </section>
  );
};

JobsHeroSection.propTypes = {
  fetchJobs: PropTypes.func,
  specialty: PropTypes.string,
  setSpecialty: PropTypes.func,
};

export default JobsHeroSection;
