import React, { useEffect, useState } from 'react';
import styles from './related-jobs.module.scss';
import JobListItem from '../../jobs-list/jobs-list-item';
import { useJobsApi } from '../../../../services/jobs-service';

const RelatedJobs = ({ jobId, queryData }) => {
  const { getSimliarJobs } = useJobsApi();
  const [similarJobs, setSimilarJobs] = useState([]);

  useEffect(() => {
    getSimliarJobs(jobId).then(({ data }) => {
      setSimilarJobs(data);
    });
  }, [jobId]);

  if (!similarJobs || similarJobs.length <= 0) {
    return null;
  }

  return (
    <section className={`section ${styles.relatedJobsSection}`}>
      <h3 className={`title is-4 has-text-centered has-text-info`}>
        Related Jobs
      </h3>
      <div>
        <div className={`columns is-centered ${styles.relatedJobList}`}>
          {similarJobs.map(job => (
            <div key={job.id} className={`column ${styles.wideJobCards}`}>
              <JobListItem {...job} queryData={queryData} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default RelatedJobs;
