import React, { useState, useEffect, useCallback } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useLocation, navigate } from '@reach/router';
import Layout from '../../layout';
import LoadingSpinner from '../../loading-spinner';
import JobsHeroSection from './jobs-hero-section';
import JobListItem from './jobs-list-item';
import NoResultsMessage from './no-results-message';
import styles from './index.module.scss';
import constants from '../../../constants/other';
import { useJobsApi } from '../../../services/jobs-service';
import { useJobFiltersService } from '../../../services/jobs-filters-service';
import ApiErrorMessage from '../../../components/error-messages/api-error-message';
import { errorMessages } from '../../../constants/error-messages';
import { urlParams } from '../../../constants/url-params';
import { getUrlFriendlyString } from '../../../utils/url-helpers';
import { useAuth } from '../../../providers/auth-provider';

/* eslint-disable react-hooks/exhaustive-deps */

const PAGE_SIZE = 20;

const formatSelectionsToFilters = (
  specialty,
  jobLocation,
  shouldUseBullhornAsJobSource
) => {
  let filters = {};

  const specialtyType = shouldUseBullhornAsJobSource
    ? 'specialtyName'
    : 'specialtyId';

  if (specialty && specialty !== constants.DEFAULT_SELECT_VALUE) {
    filters = {
      ...filters,
      [specialtyType]: specialty,
    };
  }

  if (jobLocation && jobLocation !== constants.DEFAULT_SELECT_VALUE) {
    filters = {
      ...filters,
      stateAbbreviation: jobLocation.toUpperCase(),
    };
  }

  return filters;
};

const generateInitialFilters = (
  jobSpecialty,
  jobLocation,
  shouldUseBullhornAsJobSource
) => {
  const jobFilters = {
    ...{
      limit: PAGE_SIZE,
      offset: 0,
    },
    ...formatSelectionsToFilters(
      jobSpecialty,
      jobLocation,
      shouldUseBullhornAsJobSource
    ),
  };

  return jobFilters;
};

const JobsList = ({ data }) => {
  const location = useLocation();
  const { meta } = useAuth();
  const { fetchJobs } = useJobsApi();
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [hasFetchError, setHasFetchError] = useState(false);

  const { isValidJobLocation } = useJobFiltersService();

  useEffect(() => {
    setIsLoading(true);
    setHasFetchError(false);
    setHasMore(true);
    const url = new URL(location.href);
    const params = url.searchParams;
    const jobSpecialty = params.get(urlParams.SPECIALTY);
    let jobLocation = params.get(urlParams.LOCATION);

    if (!isValidJobLocation(jobLocation)) {
      jobLocation = constants.DEFAULT_SELECT_VALUE;
    }

    const jobFilters = generateInitialFilters(
      jobSpecialty,
      jobLocation,
      meta.shouldUseBullhorn
    );

    fetchJobs(jobFilters)
      .then(({ data }) => {
        setJobs(data);
        setOffset(PAGE_SIZE);

        if (data.length === 0) {
          setHasMore(false);
        }
      })
      .catch(() => setHasFetchError(true))
      .finally(() => setIsLoading(false));
  }, [location.href]);

  const updateQueryParamsWithNewFilters = useCallback(
    (specialty, jobLocation) => {
      const safeSpecialty = specialty || constants.DEFAULT_SELECT_VALUE;
      const safeLocation =
        getUrlFriendlyString(jobLocation) || constants.DEFAULT_SELECT_VALUE;
      const newSearchParams = new URLSearchParams();
      if (safeSpecialty) {
        newSearchParams.set(urlParams.SPECIALTY, safeSpecialty);
      }
      if (safeLocation) {
        newSearchParams.set(urlParams.LOCATION, safeLocation);
      }

      navigate(`?${newSearchParams.toString()}`, { replace: true });
    },
    [navigate]
  );

  const getAdditionalJobs = useCallback(() => {
    if (hasFetchError) {
      setHasFetchError(false);
    }
    setIsLoading(true);
    setHasMore(true);

    const url = new URL(location.href);
    const params = url.searchParams;
    const jobSpecialty = params.get(urlParams.SPECIALTY);
    const jobLocation = params.get(urlParams.LOCATION);

    const jobFilters = {
      ...{
        limit: PAGE_SIZE,
        offset: offset,
      },
      ...formatSelectionsToFilters(
        jobSpecialty,
        jobLocation,
        meta.shouldUseBullhorn
      ),
    };

    fetchJobs(jobFilters)
      .then(({ data }) => {
        let updatedJobs = [];
        updatedJobs = [...jobs, ...data];
        setOffset(offset + PAGE_SIZE);

        if (data.length === 0) {
          setHasMore(false);
        }

        setJobs(updatedJobs);
      })
      .catch(() => setHasFetchError(true))
      .finally(() => setIsLoading(false));
  }, [hasFetchError, location.href, offset, jobs, hasMore, isLoading]);

  return (
    <Layout>
      <JobsHeroSection
        fetchJobs={updateQueryParamsWithNewFilters}
        data={data}
      />

      <section className={`section columns ${styles.section}`}>
        <div
          className={`column is-12 is-10-desktop is-offset-1-desktop ${styles.sectionContent}`}
        >
          {hasFetchError && (
            <ApiErrorMessage errorMsg={errorMessages.REQUEST_FAILED} />
          )}
          <InfiniteScroll
            dataLength={jobs.length}
            next={getAdditionalJobs}
            hasMore={hasMore}
            loader={<LoadingSpinner isLoading={isLoading} />}
            style={{
              overflow: 'none', // fixes issue where scrollbars are showing around loader
              minHeight: '100vh', // fixes issue where inifinite scroll sets incorrect offset on mobile
            }}
            endMessage={<NoResultsMessage jobsCount={jobs.length} />}
          >
            {jobs.map(job => (
              <JobListItem key={job.id} queryData={data} {...job} />
            ))}
          </InfiniteScroll>
        </div>
      </section>
    </Layout>
  );
};

export default JobsList;
