import React from 'react';
import styles from './job-info-row.module.scss';
import PropTypes from 'prop-types';

const JobInfoRow = ({ title, value, highlight }) => {
  return (
    <div className={`${styles.infoRow}`}>
      <span className={`${styles.itemLeft}`}>{title}: </span>
      <span
        className={`has-text-weight-semibold ${
          highlight ? styles.valueHighlighted : ''
        }`}
      >
        {value}
      </span>
    </div>
  );
};

JobInfoRow.propTypes = {
  title: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highlight: PropTypes.bool,
};

export default JobInfoRow;
