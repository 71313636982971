import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const NoResultsMessage = ({ jobsCount }) => {
  const getMessageContent = () => {
    if (jobsCount === 0) {
      return (
        <>
          <p className="content has-text-weight-semibold">
            No jobs found in this location.
          </p>
          <p className="content has-text-weight-semibold">
            We get hundreds of new positions every day. Don't miss out on the
            best locations or the highest-paying jobs for your specialty!
          </p>
          <Link to="/apply" className="button is-primary">
            Apply now
          </Link>
        </>
      );
    }

    return (
      <p className="has-text-weight-semibold">All jobs have been loaded.</p>
    );
  };

  return (
    <div className="columns">
      <div className="column is-8 is-offset-2 has-text-centered">
        {getMessageContent()}
      </div>
    </div>
  );
};

NoResultsMessage.propTypes = {
  jobsCount: PropTypes.number,
};

export default NoResultsMessage;
