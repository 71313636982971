import React, { useEffect, useState } from 'react';
import Layout from '../../layout';
import LoadingSpinner from '../../loading-spinner';
import JobDetailsSection from './job-details-section.js';
import { useJobsApi } from '../../../services/jobs-service';
import ApiErrorMessage from '../../error-messages/api-error-message';
import { errorMessages } from '../../../constants/error-messages';
import styles from './index.module.scss';

const JobDetails = ({ jobSlug, queryData, location }) => {
  const { getJobDetails } = useJobsApi();
  const [job, setJob] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const jobIdMatches = jobSlug.match(/\d+(?!.*-)/);
  const jobId = jobIdMatches ? jobIdMatches[0] : undefined;

  const hasFetchError = !isLoading && !Object.keys(job).length;

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }
    getJobDetails(jobId)
      .then(({ data }) => {
        setJob(data);
      })
      .finally(() => setIsLoading(false));
  }, [jobId]);

  return (
    <Layout>
      {isLoading ? (
        <LoadingSpinner isLoading={isLoading} />
      ) : hasFetchError ? (
        <div className={styles.errorMessage}>
          <ApiErrorMessage errorMsg={errorMessages.REQUEST_FAILED} />
        </div>
      ) : (
        <JobDetailsSection
          job={job}
          queryData={queryData}
          location={location}
        />
      )}
    </Layout>
  );
};

export default JobDetails;
