const jobDetailsService = {
  getStateInfoText: getStateInfoText,
};

function getStateInfoText(job) {
  return (
    `${job.state.description} Not licensed in ${job.state.name}? ` +
    `Trustaff will foot the bill! Licensure reimbursement is one of the many ` +
    `perks offered to Trustaff travelers!`
  );
}

export default jobDetailsService;
