import React, { useState } from 'react';
import { Link } from 'gatsby';

import { useAuth } from '../../providers/auth-provider';
import JobApplicationForm from '../job-application-form/job-application-form';
import formLocations from '../job-application-form/form-locations';
import { useJobApplicationsApi } from '../../services/job-applications-service';
import LoadingSpinner from '../loading-spinner';
import styles from './job-application.module.scss';

import PopupNotification from '../popup-notification';
import ApiErrorMessage from '../error-messages/api-error-message';

const JobApplication = ({ job }) => {
  const { createQuickApplyApplication } = useJobApplicationsApi();
  const { loggedIn, getProfileId } = useAuth();

  const [hasAppliedForJob, setHasAppliedForJob] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasApiError, setHasApiError] = useState(false);
  const [showSuccessNotification, setShowSuccessNotification] = useState(false);

  const isJobClosed = job.statusName === 'Closed';

  const handleOneClickApply = () => {
    setIsLoading(true);
    createQuickApplyApplication(getProfileId(), job.id)
      .then(() => {
        setHasAppliedForJob(true);
        setShowSuccessNotification(true);
      })
      .catch(() => {
        setHasApiError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getApplicationTitle = () => {
    if (isJobClosed) {
      return (
        <>
          <h3 className={`title is-5 ${styles.jobApplicationText}`}>
            This job has been filled.
          </h3>
          {!loggedIn && (
            <p className={`content ${styles.jobApplicationText}`}>
              Things move pretty fast around here! Complete the form below to be
              notified when a similar job opens.
            </p>
          )}
        </>
      );
    }

    return (
      <h3
        className={`title is-5 has-text-centered ${styles.jobApplicationText}`}
      >
        {job.specialtyName}
        <span className={styles.titleSeparator}>•</span>#{job.id}
      </h3>
    );
  };

  return (
    <>
      <section
        className={`section ${styles.jobApplicationSection} ${
          isJobClosed ? styles.jobApplicationSectionClosed : ''
        }`}
      >
        <div className={`columns`}>
          <div className={`column has-text-centered`}>
            {getApplicationTitle(job.statusName)}

            {loggedIn ? (
              !hasAppliedForJob ? (
                isLoading ? (
                  <LoadingSpinner isLoading={isLoading} />
                ) : (
                  !isJobClosed && (
                    <button
                      className={`button is-info`}
                      onClick={handleOneClickApply}
                    >
                      Apply Now
                    </button>
                  )
                )
              ) : (
                <>
                  <hr className={styles.divider} />
                  <p className={`${styles.jobApplicationText}`}>
                    Thank you for applying to this position
                  </p>
                </>
              )
            ) : (
              <>
                <JobApplicationForm
                  job={job}
                  formLocation={formLocations.JOB_DETAILS}
                />
                <div className={`${styles.CCPA}`}>
                  <Link to="/privacy-notice/" className={`is-size-7`}>
                    CCPA Privacy Notice for Job Applicants
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
        {hasApiError && (
          <ApiErrorMessage errorMsg="There was a problem saving your application." />
        )}
      </section>
      {showSuccessNotification && (
        <PopupNotification
          message="Your application has been submitted!"
          duration="medium"
          onClose={() => setShowSuccessNotification(false)}
        />
      )}
    </>
  );
};

export default JobApplication;
