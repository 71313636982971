import constants from '../../../../constants/other';

const displayedFieldValueHelper = {
  getDuration: getDuration,
  getShift: getShift,
};

function getDuration(durationInWeeks) {
  if (
    !durationInWeeks ||
    durationInWeeks === constants.DEFAULT_MISSING_FIELD_DESCRIPTION_IN_DB
  ) {
    return constants.DEFAULT_MISSING_FIELD_DESCRIPTION_IN_CLIENT_VIEW;
  }

  return `${durationInWeeks} week${durationInWeeks !== '1' ? 's' : ''}`;
}

function getShift(shiftName) {
  if (
    !shiftName ||
    shiftName === constants.DEFAULT_MISSING_FIELD_DESCRIPTION_IN_DB
  ) {
    return constants.DEFAULT_MISSING_FIELD_DESCRIPTION_IN_CLIENT_VIEW;
  }

  return `${shiftName}`;
}

export default displayedFieldValueHelper;
