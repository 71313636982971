import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import styles from './job-details-section.module.scss';
import JobDetailsHead from './job-details-head';
import JobDetailsHero from './job-details-hero';
import jobDetailsService from '../../../services/job-details-service.js';
import PropTypes from 'prop-types';
import { getFormattedLocation } from '../../../utils/format-helpers';
import JobApplication from '../../job-application/job-application.js';
import RelatedJobs from './related-jobs/related-jobs';

const JobDetailsSection = ({ job, queryData, location }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const getBenefitsTitle = ({ name }) => {
    if (name === 'Travel') {
      return 'Why become a Trustaff traveler?';
    }

    return 'Why choose Trustaff?';
  };

  const handleKeepReadingClick = () => {
    setIsExpanded(!isExpanded);
  };

  const getImgEdgeContains = str => {
    return queryData.allImageSharp.edges.find(e =>
      e.node.fluid.originalName.includes(str)
    );
  };

  const getStateImgEdge = () => {
    if (job.state === null) {
      // if job doesn't have a state use the default image
      return getImgEdgeContains('default');
    }

    // return images for a given state or default if not found
    return (
      getImgEdgeContains(job.state.abbreviation) ||
      getImgEdgeContains('default')
    );
  };

  return (
    <>
      <JobDetailsHead
        ogImage={getStateImgEdge().node.fluid.src}
        ogUrl={location.href}
        {...job}
      />
      <JobDetailsHero
        job={job}
        queryData={queryData}
        stateImgEdge={getStateImgEdge()}
      />

      <section className="section">
        <div className="columns">
          <div
            className={`column is-6 is-offset-1 has-text-centered-mobile ${
              styles.descriptions
            } ${isExpanded ? styles.isExpanded : ''}`}
          >
            <h2 className="title is-4">About this job</h2>
            <p className="content">{job.specialtyDescription}</p>
            <hr />

            {job.professionDescription === 'Nursing' ? (
              <>
                {' '}
                <h3 className={`title is-5 ${styles.subsectionHeading}`}>
                  {job.division.name} {job.specialtyName} requirements
                </h3>
                <p
                  className={`content ${styles.bullets}`}
                  dangerouslySetInnerHTML={{
                    __html: job.division.requirementsHtml,
                  }}
                ></p>
                <hr />
              </>
            ) : null}

            <h3 className={`title is-5 ${styles.subsectionHeading}`}>
              {job.division.name} {job.professionDescription.toLowerCase()} in{' '}
              {getFormattedLocation(job.city, job.state, false)}
            </h3>
            <p className="content">{jobDetailsService.getStateInfoText(job)}</p>
            <hr />
            <h3 className={`title is-5 ${styles.subsectionHeading}`}>
              {getBenefitsTitle(job.division)}
            </h3>
            <p
              className={`content ${styles.bullets}`}
              dangerouslySetInnerHTML={{
                __html: job.division.benefitsHtml,
              }}
            ></p>
            <p className="content">
              For more information, apply online or call (877) 880-0346 to speak
              with a Trustaff representative right away. Trustaff is an Equal
              Opportunity Employer.
            </p>
          </div>
          <div
            className={`${styles.keepReadingWrapper} ${
              isExpanded ? styles.isExpanded : ''
            }`}
          >
            <button
              className={`button is-info is-outlined ${styles.keepReadingBtn}`}
              onClick={handleKeepReadingClick}
              aria-expanded={isExpanded}
            >
              <span>Keep reading</span>
              <span className="icon is-small">
                <FontAwesomeIcon icon={faCaretDown} />
              </span>
            </button>
          </div>
          <div
            className={`column is-3 is-offset-1 ${styles.applyForJobSection}`}
          >
            <JobApplication job={job} />
          </div>
        </div>
      </section>
      <RelatedJobs jobId={job.id} queryData={queryData} />
    </>
  );
};

JobDetailsSection.propTypes = {
  job: PropTypes.object,
};

export default JobDetailsSection;
