import React from 'react';
import styles from './job-details-hero.module.scss';
import JobInfoRow from './job-info-row';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import {
  getFormattedLocation,
  getFormattedDate,
} from '../../../utils/format-helpers';
import displayHelper from './helpers/displayed-field-value-helper';

const JobDetailsHero = ({ job, queryData, stateImgEdge }) => {
  const getStartDate = ({ startDate, statusName }) => {
    if (statusName === 'Closed') {
      return 'Position filled';
    }
    return getFormattedDate(startDate);
  };

  return (
    <section className={`${styles.heroSection}`}>
      <Img className={`${styles.imgWrapper}`} fluid={stateImgEdge.node.fluid} />
      <div
        className={`${styles.heroOverlay} ${
          job.statusName === 'Closed' ? styles.heroOverlayClosed : ''
        }`}
      >
        <div className={`${styles.heroContent}`}>
          <div className={`columns ${styles.heroColumns}`}>
            <div
              className={`column is-6-desktop is-7-tablet is-offset-1 ${styles.heroColumn}`}
            >
              <div className={`${styles.heroDescription}`}>
                <h1 className={`title is-3 ${styles.whiteText}`}>
                  {job.specialtyName}
                </h1>
                <p className={`subtitle is-4 is-italic ${styles.whiteText}`}>
                  {getFormattedLocation(job.city, job.state)}
                </p>
                <div className={`${styles.whiteText}`}>
                  Trustaff is currently seeking a skilled {job.specialtyName}{' '}
                  for a new {job.division.name.toLowerCase()}{' '}
                  {job.professionDescription.toLowerCase()} job in{' '}
                  {getFormattedLocation(job.city, job.state, false)}.
                </div>
              </div>
              <div className={`columns ${styles.toplineInfo}`}>
                <div className={`column`}>
                  <div className={`columns`}>
                    <div
                      className={`column one-half ${styles.minWidthJobDetailsCol}`}
                    >
                      <div className={'rows'}>
                        <JobInfoRow
                          title="Specialty"
                          value={job.specialtyName}
                        />
                        <JobInfoRow
                          title="Location"
                          value={getFormattedLocation(job.city, job.state)}
                        />
                        <JobInfoRow
                          title="Duration"
                          value={`${displayHelper.getDuration(
                            job.durationInWeeks
                          )}`}
                        />
                        <JobInfoRow
                          title="Start date"
                          value={getStartDate(job)}
                          highlight={job.statusName === 'Closed'}
                        />
                      </div>
                    </div>
                    <div
                      className={`column one-half ${styles.minWidthJobDetailsCol}`}
                    >
                      <div className="rows">
                        <JobInfoRow
                          title="Shift"
                          value={displayHelper.getShift(job.shiftType)}
                        />
                        <JobInfoRow title="Job ID" value={job.id} />
                        <JobInfoRow
                          title="Status"
                          value={job.statusName}
                          highlight={job.statusName === 'Closed'}
                        />
                        <div className="is-sr-only">
                          <JobInfoRow
                            title="Pay"
                            value={`$${job.hourlyPayRate}/hour`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

JobDetailsHero.propTypes = {
  job: PropTypes.object,
};

export default JobDetailsHero;
