import React from 'react';
import { Router } from '@reach/router';
import JobsList from './jobs-list';
import JobDetails from './job-details';

const JobsRouter = ({ data, mounted }) => {
  if (mounted) {
    // only rendering the router on mount prevents the SSR gatsby page from
    // flashing the /jobs page when /jobs/:jobId URL visited directly
    return (
      <Router>
        <JobsList path="/jobs" data={data} />
        <JobDetails path="/jobs/:jobSlug" queryData={data} />
        <JobDetails path="/jobs/:location/:jobSlug" queryData={data} />
      </Router>
    );
  }
  return null;
};

export default JobsRouter;
